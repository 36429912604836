import React from "react";
import { IconProps } from "./Icon.d";

import Plus from "./icons/Plus";
import FullLogo from "./icons/FullLogo";
import AppStore from "./icons/AppStore";
import PlayMarket from "./icons/PlayMarket";
import FaceBook from "./icons/FaceBook";
import LinkedIn from "./icons/LinkedIn";
import Visa from "./icons/Visa";
import MasterCard from "./icons/MasterCard";
import ShortLogo from "./icons/ShortLogo";
import YouTube from "./icons/YouTube";
import Close from "./icons/Close";
import Edit from "./icons/Edit";
import Deface from "./icons/Deface";
import ChatWTotem from "./icons/ChatWTotem";
import ChatPS from "./icons/ChatPS";
import ShortLogoGradient from "./icons/ShortLogoGradient";
import Eye from "./icons/Eye";
import TrashWithBorder from "./icons/TrashWithBorder";
import Spinner from "./icons/Spinner";
import Trash from "./icons/Trash";
import File from "./icons/File";

const Icon = ({ name, onClick, ...props }: IconProps) => {
  const getIcon = () => {
    switch (name) {
      case "plus":
        return <Plus {...props} />;
      case "full-logo":
        return <FullLogo {...props} />;
      case "app-store":
        return <AppStore {...props} />;
      case "play-market":
        return <PlayMarket {...props} />;
      case "facebook":
        return <FaceBook {...props} />;
      case "linkedin":
        return <LinkedIn {...props} />;
      case "visa":
        return <Visa {...props} />;
      case "master-card":
        return <MasterCard {...props} />;
      case "short-logo":
        return <ShortLogo {...props} />;
      case "youtube":
        return <YouTube {...props} />;
      case "close":
        return <Close {...props} />;
      case "edit":
        return <Edit {...props} />;
      case "deface":
        return <Deface {...props} />;
      case "chat-wtotem":
        return <ChatWTotem {...props} />;
      case "chat-ps":
        return <ChatPS {...props} />;
      case "short-logo-gradient":
        return <ShortLogoGradient {...props} />;
      case "eye":
        return <Eye {...props} />;
      case "trash-with-border":
        return <TrashWithBorder {...props} />;
      case "trash":
        return <Trash {...props} />;
      case "spinner":
        return <Spinner {...props} />;
      case "file":
        return <File {...props} />;
      default:
        return <div />;
    }
  };

  if (onClick) {
    return (
      <div
        className="cursor-pointer flex items-center justify-center"
        onClick={onClick}
      >
        {getIcon()}
      </div>
    );
  } else {
    return getIcon();
  }
};

export default Icon;
