import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "141px",
  height = "30px",
  viewBox = "0 0 141 30",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.312 8.3086L133.132 15.144L128.953 8.3086H125.433V23.6941H128.953V14.7474L132.935 21.2751H133.33L137.312 14.7474V23.6923L140.832 20.2897V8.3086H137.312ZM117.298 17.5843H122.797V14.2431H117.298V11.6925H123.348V8.30771H113.778V23.6932H123.457V20.3084H117.298V17.5826V17.5843ZM100.803 11.6925H104.762V23.6923H108.282V11.6934H112.242V8.3086H100.803V11.6934V11.6925ZM92.8 20.5716C90.293 20.5716 88.3124 18.7485 88.3124 16.0004C88.3124 13.2533 90.293 11.4293 92.8 11.4293C95.3079 11.4293 97.2876 13.2533 97.2876 16.0004C97.2876 18.7485 95.3079 20.5716 92.8 20.5716ZM92.8 8C88.3791 8 84.7933 11.4728 84.7933 16.0004C84.7933 20.528 88.3791 24 92.8 24C97.2227 24 100.808 20.528 100.808 16.0004C100.808 11.4728 97.2227 8 92.8 8ZM84.796 8.30771H73.3574V11.6925H77.3176V23.6923H80.8367V11.6934H84.796V8.3086V8.30771ZM68.1699 20.3964H65.2209V17.4527H68.169C69.0486 17.4527 69.6204 18.0664 69.6204 18.9246C69.6204 19.7819 69.0486 20.3964 68.169 20.3964H68.1699ZM65.2218 11.6054H67.7297C68.5434 11.6054 69.0717 12.1772 69.0717 12.9678C69.0717 13.7593 68.5221 14.3312 67.7297 14.3312H65.2218V11.6054ZM71.2497 15.7159C72.0857 14.9902 72.5917 13.979 72.5917 12.7046C72.5917 10.0455 70.4128 8.3086 67.7297 8.3086H61.7027V23.6941H68.1699C70.9197 23.6941 73.1413 21.9127 73.1413 19.1878C73.1413 17.6279 72.3934 16.4407 71.2497 15.7159ZM53.5671 17.5835H59.0667V14.2431H53.5671V11.6925H59.6163V8.30771H50.0471V23.6932H59.7248V20.3084H53.5662V17.5826L53.5671 17.5835ZM42.1569 19.0775L39.1865 8.3086H36.3273L33.357 19.0784L30.6961 8.3086H27L31.2457 23.6941H35.2495L37.7574 14.6166L40.2653 23.6932H44.2691L48.5139 8.3086H44.8187L42.156 19.0784L42.1569 19.0775Z"
      fill="#1D293F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.16166 1.70496H11.8122V0H6.16166V1.70496ZM6.16166 4.40413H11.8122V2.72727H6.16166V4.40413ZM16.1975 19.5223L10.273 25.5909L12.0068 27.3669L16.1975 23.0719V19.5223ZM16.1975 14.5041L12.0371 18.0967V21.1215L16.1975 16.8587V14.5041ZM12.0371 11.9496V15.5198C13.1927 14.357 15.8793 12.5529 15.8793 12.5529L16.0535 12.4066L12.0371 11.9496ZM16.1975 7.32975H1.78045V10.5926L5.96456 10.1165V10.1149H7.76382V23.0215L8.97424 24.2612L10.2378 22.9653V10.1132H12.0371V10.1165L16.1975 10.5901V7.32975ZM5.96456 15.4339V11.9488L1.87941 12.4149L2.13295 12.5041C2.13295 12.5041 4.78277 14.2694 5.96456 15.4339ZM1.78045 16.8901L5.96456 21.1777V18.0141L1.78045 14.4289V16.8901ZM1.78045 23.1008L5.9433 27.3653L7.67468 25.5909L1.78045 19.5529V23.1008ZM18 17.676L17.9967 17.6785V23.8355H17.9477L17.9992 23.8893L12.0354 30L11.9537 29.9174L11.9242 29.9463L8.97424 26.9231L5.97028 30L5.89014 29.9182L5.86315 29.9471L0.000818178 23.9397L0.107138 23.8306H0.00490751V17.7331L0 17.7289L0.00490751 17.7231V12.6281H0V10.7934H0.00490751V5.51157H17.9975V17.6711L17.9992 17.6752L18 17.676Z"
      fill="#3D50DF"
    />
  </svg>
);

export default SVG;
