import React, { lazy, Suspense } from "react";
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  useLocation,
} from "react-router-dom";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";

const Password = lazy(() =>
  import("./pages/Password").then(({ Password }) => ({
    default: Password,
  }))
);
const SignIn = lazy(() =>
  import("./pages/SignIn").then(({ SignIn }) => ({
    default: SignIn,
  }))
);
const Incidents = lazy(() =>
  import("./pages/Incidents").then(({ Incidents }) => ({
    default: Incidents,
  }))
);

const Routes = () => {
  const pathname = useLocation().pathname;
  return (
    <>
      {pathname !== "/password" && pathname !== "/sign-in" && <Header />}
      <Suspense fallback={<div />}>
        <div className="mx-10">
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/incidents" />} />
            <Route exact path="/password" component={Password} />
            <Route exact path="/sign-in" component={SignIn} />
            <PrivateRoute path="/incidents" component={Incidents} />
          </Switch>
        </div>
      </Suspense>
      {pathname !== "/password" && pathname !== "/sign-in" && <Footer />}
    </>
  );
};

const PrivateRoute = ({ component: Component, ...props }: RouteProps) => {
  return (
    <Route
      {...props}
      render={(routeProps) =>
        !localStorage.getItem("tokenData") ? (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: routeProps.location },
            }}
          />
        ) : (
          <Component {...routeProps} />
        )
      }
    />
  );
};
export default Routes;
