import React from "react";
import { Icon } from "../Icon";

const Footer = () => {
  return (
    <footer className="mt-8 mx-10">
      <div className="flex items-center justify-between border-b border-geyser py-8">
        <div className="flex items-center gap-3">
          <Icon name="short-logo" />
          Your best friend in cybersecurity world
        </div>
        <div className="flex gap-5">
          <a
            href="https://linkedin.com/company/wtotem"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="linkedin" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCD-n_NIXTOmw4Nm-LcmW1XA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="youtube" />
          </a>
          <a
            href="https://www.facebook.com/webtotem"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="facebook" />
          </a>
        </div>
      </div>
      <div className="flex items-center justify-between py-8">
        <div className="flex items-center gap-4">
          © 2017-2019 All rights reserved
          <div className="flex items-center gap-3">
            <a
              href="https://apps.apple.com/us/app/webtotem/id1289069562"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="app-store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.indepo.webtotem"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="play-market" />
            </a>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <Icon name="visa" />
          <Icon name="master-card" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
