import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "24px",
  height = "24px",
  fill = "#E1E8EE",
  className = "",
  viewBox = "0 0 24 24",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.38338 0 0 5.38292 0 12C0 18.6171 5.38338 24 12 24C18.6166 24 24 18.6171 24 12C24 5.38292 18.6166 0 12 0ZM12 23.0769C5.89246 23.0769 0.923077 18.1075 0.923077 12C0.923077 5.89246 5.89246 0.923077 12 0.923077C18.1075 0.923077 23.0769 5.89246 23.0769 12C23.0769 18.1075 18.1075 23.0769 12 23.0769Z"
      fill={fill}
    />
    <path
      d="M16.4804 7.51985C16.3 7.33939 16.0083 7.33939 15.8278 7.51985L12.0003 11.3474L8.17273 7.51985C7.99227 7.33939 7.70057 7.33939 7.52011 7.51985C7.33965 7.70031 7.33965 7.99201 7.52011 8.17247L11.3477 12L7.52011 15.8275C7.33965 16.008 7.33965 16.2997 7.52011 16.4802C7.61011 16.5702 7.72827 16.6154 7.84642 16.6154C7.96457 16.6154 8.08273 16.5702 8.17273 16.4802L12.0003 12.6526L15.8278 16.4802C15.9178 16.5702 16.036 16.6154 16.1541 16.6154C16.2723 16.6154 16.3904 16.5702 16.4804 16.4802C16.6609 16.2997 16.6609 16.008 16.4804 15.8275L12.6529 12L16.4804 8.17247C16.6609 7.99201 16.6609 7.70031 16.4804 7.51985Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
