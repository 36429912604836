import * as Types from '../../../__generated__/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
export type ViewerNameQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ViewerNameQuery = (
  { __typename: 'Query' }
  & { auth?: Types.Maybe<(
    { __typename: 'AuthQueries' }
    & { viewer?: Types.Maybe<(
      { __typename: 'Viewer' }
      & Pick<Types.Viewer, 'name'>
    )> }
  )> }
);


export const ViewerNameQueryDocument = gql`
    query ViewerNameQuery {
  auth {
    viewer {
      name
    }
  }
}
    `;

/**
 * __useViewerNameQuery__
 *
 * To run a query within a React component, call `useViewerNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerNameQuery, ViewerNameQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerNameQuery, ViewerNameQueryVariables>(ViewerNameQueryDocument, baseOptions);
      }
export function useViewerNameQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerNameQuery, ViewerNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerNameQuery, ViewerNameQueryVariables>(ViewerNameQueryDocument, baseOptions);
        }
export type ViewerNameQueryHookResult = ReturnType<typeof useViewerNameQuery>;
export type ViewerNameQueryLazyQueryHookResult = ReturnType<typeof useViewerNameQueryLazyQuery>;
export type ViewerNameQueryQueryResult = ApolloReactCommon.QueryResult<ViewerNameQuery, ViewerNameQueryVariables>;