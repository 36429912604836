import React from "react";
import PsLogo from "../../../assets/images/ps-logo.jpeg";
const Image = ({ width = "28px", height = "9px", className = "" }) => (
  <img
    src={PsLogo}
    alt="ps logo"
    className={className}
    width={width}
    height={height}
  />
);

export default Image;
