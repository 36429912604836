import { toast } from "react-toastify";

const ignoreErrors = ['Unexpected error value: "API_KEY_NOT_BELONG_TO_USER"'];
const getErrorDescription = (error: string) => {
  switch (error) {
    case "INVALID_CREDENTIALS":
      return "Invalid credentials";
    case "RESOURCE_NOT_FOUND":
      return "Resource not found, please check the file and try again, or contact support";
    case "CHECK_LOCK_10_SEC":
      return "Please try again after 10 seconds";
    case "HOSTS_LIMIT_EXCEEDED":
      return "Your tariff does not allow you to add more sites. Please change your tariff.";
    case "DUPLICATE_HOST":
      return "This site has already been added";
    case "INVALID_TOKEN":
      return "Invalid token";
    case "INVALID_PASSWORD":
      return "Invalid password";
    default:
      return error;
  }
};

export const errorHandler = (error: string) => {
  if (error === "INVALID_TOKEN") {
    localStorage.removeItem("tokenData");
    window.location.href = "/sign-in";
  } else if (!Object.values(ignoreErrors).includes(error)) {
    toast(getErrorDescription(error));
  }
};
