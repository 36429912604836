import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  className = "",
  fill = "#3D50DF",
  width = "62px",
  height = "62px",
  viewBox = "0 0 62 62",
}: SVGProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <circle cx="31" cy="29" r="27" fill="white" />
      <circle
        cx="31"
        cy="29"
        r="26"
        stroke="url(#paint0_linear)"
        strokeWidth="2"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9254 13.8964H34.2763V12H27.9254V13.8964ZM27.9254 16.8985H34.2763V15.0334H27.9254V16.8985ZM39.2052 33.7138L32.5463 40.4637L34.4951 42.4391L39.2052 37.6619V33.7138ZM39.2052 28.1323L34.5291 32.1282V35.4925L39.2052 30.7512V28.1314V28.1323ZM34.5291 25.291V29.262C35.8279 27.9687 38.8476 25.962 38.8476 25.962L39.0434 25.7993L34.5291 25.291ZM39.2052 20.1526H23.0011V23.7816L27.7039 23.2522V23.2503H29.7262V37.6058L31.0866 38.9846L32.5068 37.5433V23.2485H34.5291V23.2522L39.2052 23.7789V20.1535V20.1526ZM27.7039 29.1664V25.2901L23.1124 25.8085L23.3973 25.9078C23.3973 25.9078 26.3756 27.8713 27.7039 29.1664ZM23.0011 30.7861L27.7039 35.555V32.0362L23.0011 28.0487V30.7861ZM23.0011 37.694L27.68 42.4372L29.626 40.4637L23.0011 33.7478V37.695V37.694ZM41.2311 31.6603L41.2274 31.663V38.5112H41.1723L41.2302 38.571L34.5272 45.3677L34.4353 45.2758L34.4022 45.3079L31.0866 41.9454L27.7103 45.3677L27.6202 45.2767L27.5899 45.3089L21.0009 38.6271L21.1204 38.5057H21.0055V31.7237L21 31.7191L21.0055 31.7127V26.0457H21V24.005H21.0055V18.1303H41.2284V31.6548L41.2302 31.6594L41.2311 31.6603Z"
      fill={fill}
    />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width={width}
        height={height}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="7.8625"
        y1="4.17023"
        x2="51.98"
        y2="8.68772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3B3B" />
        <stop offset="1" stopColor={fill} />
      </linearGradient>
    </defs>
  </svg>
);

export default SVG;
