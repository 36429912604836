import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "40px",
  height = "40px",
  viewBox = "0 0 40 40",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={width}
      height={height}
      rx="20"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9897 26V19.9993H22.7648L23 17.9314H20.9897L20.9928 16.8964C20.9928 16.3571 21.0477 16.0681 21.8778 16.0681H22.9874V14H21.2122C19.0798 14 18.3292 15.0031 18.3292 16.6901V17.9317H17V19.9995H18.3292V26H20.9897Z"
      fill="#5E6977"
    />
  </svg>
);

export default SVG;
