import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "44px",
  height = "14px",
  viewBox = "0 0 44 14",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="#clip0">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0553 0C30.4124 0 31.4992 0.294775 32.1924 0.569806L31.7186 3.50944L31.4033 3.35277C30.759 3.07774 29.9287 2.81402 28.7852 2.83313C27.4144 2.83313 26.78 3.43602 26.78 3.99963C26.7722 4.63518 27.5221 5.05354 28.746 5.6813C30.7649 6.64994 31.697 7.82381 31.6832 9.36567C31.6558 12.1819 29.2688 14 25.5894 14C24.0189 13.9836 22.5072 13.6557 21.6907 13.2773L22.1821 10.2407L22.6325 10.4559C23.782 10.9627 24.5262 11.1674 25.9262 11.1674C26.9328 11.1674 28.0116 10.7528 28.0214 9.84369C28.0272 9.24998 27.5691 8.82596 26.2062 8.16096C24.8785 7.51249 23.1201 6.42443 23.1396 4.47606C23.1612 1.8393 25.5972 0 29.0553 0ZM18.1874 0.235752L16 13.7986H19.4973L21.6886 0.235752H18.1874ZM41.1668 0.248779H38.4624C37.6243 0.248779 36.9978 0.490192 36.6296 1.37345L31.4325 13.8037H35.108C35.108 13.8037 35.7072 12.1324 35.8442 11.7653C36.0329 11.7653 36.9211 11.7667 37.8628 11.7681C38.9252 11.7698 40.0556 11.7716 40.3265 11.7716C40.4303 12.2462 40.7515 13.8037 40.7515 13.8037H44.0001L41.1668 0.248779ZM38.2452 5.19954C38.2452 5.19954 37.1406 8.20962 36.8509 8.99149H39.7568C39.6159 8.31197 38.9463 5.07436 38.9463 5.07436L38.7092 3.90475C38.6124 4.18454 38.476 4.55859 38.376 4.83291C38.2939 5.05794 38.2363 5.21585 38.2452 5.19954ZM9.2687 9.49513L12.7324 0.245576H16.4789L10.9094 13.783L7.16671 13.7882L4 1.92575C6.24639 3.10058 8.25534 5.44822 8.89861 7.61602L9.2687 9.49513Z"
        fill="#5E6977"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75116 0.318237H0.0590227L0.015625 0.594931C4.44219 1.69741 7.37215 4.36094 8.58648 7.56162L7.35045 1.44209C7.13724 0.598912 6.51724 0.347677 5.75116 0.318237Z"
        fill="#86939E"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
