import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  fill = "#3D50DF",
  width = "46px",
  height = "46px",
  viewBox = "0 0 46 46",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="#filter0_d">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 44C35.1503 44 45 34.1503 45 22C45 9.84974 35.1503 0 23 0C10.8497 0 1 9.84974 1 22C1 34.1503 10.8497 44 23 44Z"
        fill={fill}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1617 8.70496H25.8122V7H20.1617V8.70496ZM20.1617 11.4041H25.8122V9.72727H20.1617V11.4041ZM30.1975 26.5223L24.273 32.5909L26.0068 34.3669L30.1975 30.0719V26.5223ZM30.1975 21.5041L26.0371 25.0967V28.1215L30.1975 23.8587V21.5041ZM26.0371 18.9496V22.5198C27.1927 21.357 29.8793 19.5529 29.8793 19.5529L30.0535 19.4066L26.0371 18.9496ZM30.1975 14.3298H15.7805V17.5926L19.9646 17.1165V17.1149H21.7638V30.0215L22.9742 31.2612L24.2378 29.9653V17.1132H26.0371V17.1165L30.1975 17.5901V14.3298ZM19.9646 22.4339V18.9488L15.8794 19.4149L16.1329 19.5041C16.1329 19.5041 18.7828 21.2694 19.9646 22.4339ZM15.7805 23.8901L19.9646 28.1777V25.0141L15.7805 21.4289V23.8901ZM15.7805 30.1008L19.9433 34.3653L21.6747 32.5909L15.7805 26.5529V30.1008ZM32 24.676L31.9967 24.6785V30.8355H31.9477L31.9992 30.8893L26.0354 37L25.9537 36.9174L25.9242 36.9463L22.9742 33.9231L19.9703 37L19.8901 36.9182L19.8631 36.9471L14.0008 30.9397L14.1071 30.8306H14.0049V24.7331L14 24.7289L14.0049 24.7231V19.6281H14V17.7934H14.0049V12.5116H31.9975V24.6711L31.9992 24.6752L32 24.676Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width={width}
        height={height}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SVG;
