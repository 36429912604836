import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "32px",
  height = "32px",
  fill = "#fff",
  className = "",
  viewBox = "0 0 72 72",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="24x24/On Light/About">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 9C12.75 7.75736 13.7574 6.75 15 6.75H57C58.2426 6.75 59.25 7.75736 59.25 9V42C59.25 42.5967 59.0129 43.169 58.591 43.591L37.591 64.591C37.169 65.0129 36.5967 65.25 36 65.25H15C13.7574 65.25 12.75 64.2426 12.75 63V9ZM17.2502 11.25V60.75H33.75V42.0002C33.75 40.7575 34.7574 39.7502 36 39.7502H54.7502V11.25H17.2502ZM51.568 44.2502H38.25V57.5682L51.568 44.2502Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default SVG;
