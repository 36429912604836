import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "36px",
  height = "36px",
  viewBox = "0 0 36 36",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={parseInt(width) / 2}
      cy={parseInt(height) / 2}
      r={parseInt(height) / 2}
      fill="#F3F5F6"
      stroke="#E1E8EE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10.5C14.6863 10.5 12 13.1863 12 16.5V23.75C12 24.3023 12.4477 24.75 13 24.75H23C23.5523 24.75 24 24.3023 24 23.75V16.5C24 13.1863 21.3137 10.5 18 10.5ZM15.75 15.75C14.5074 15.75 13.5 16.7574 13.5 18C13.5 19.2426 14.5074 20.25 15.75 20.25H20.25C21.4926 20.25 22.5 19.2426 22.5 18C22.5 16.7574 21.4926 15.75 20.25 15.75H15.75Z"
      fill="#3D50DF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 18C16.5 18.4142 16.1642 18.75 15.75 18.75C15.3358 18.75 15 18.4142 15 18C15 17.5858 15.3358 17.25 15.75 17.25C16.1642 17.25 16.5 17.5858 16.5 18ZM21 18C21 18.4142 20.6642 18.75 20.25 18.75C19.8358 18.75 19.5 18.4142 19.5 18C19.5 17.5858 19.8358 17.25 20.25 17.25C20.6642 17.25 21 17.5858 21 18Z"
      fill="#3D50DF"
    />
  </svg>
);

export default SVG;
