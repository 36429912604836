import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "19px",
  height = "19px",
  viewBox = "0 0 19 19",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M17.0196 7.68662L4.05384 0.275297C3.73877 0.0951718 3.38029 0 3.01734 0C2.64966 0 2.28721 0.0975363 1.96918 0.282053C1.32739 0.65438 0.928711 1.34659 0.928711 2.08863V16.9114C0.928711 17.6533 1.32739 18.3455 1.96901 18.7179C2.28704 18.9025 2.64957 19.0001 3.01726 19.0001C3.38004 19.0001 3.73852 18.9048 4.05376 18.7247L17.0193 11.3133C17.6684 10.9422 18.0716 10.2475 18.0716 9.49995C18.0716 8.75243 17.6686 8.05768 17.0196 7.68662ZM11.4011 5.93418L9.62085 8.41388L5.3626 2.48249L11.4011 5.93418ZM2.9356 17.7261C2.81982 17.7149 2.70624 17.6811 2.60482 17.6222C2.35232 17.4757 2.19542 17.2033 2.19542 16.9114V2.08854C2.19542 1.79661 2.35232 1.52418 2.6049 1.37767C2.70598 1.31898 2.81864 1.28402 2.93441 1.27211L8.84115 9.49995L2.9356 17.7261ZM5.3626 16.5175L9.62085 10.586L11.4011 13.0657L5.3626 16.5175ZM16.3909 10.2134L12.5067 12.4337L10.4005 9.49995L12.5067 6.56618L16.3909 8.78646C16.6463 8.93247 16.8049 9.20582 16.8049 9.49995C16.8049 9.79408 16.6463 10.0675 16.3909 10.2134Z"
        fill="#5E6977"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
