import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  style = {},
  fill = "#FFFFFF",
  width = "10px",
  height = "10px",
  className = "",
  viewBox = "0 0 10 10",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4.61621 4.47942L4.61621 0.843524C4.61621 0.609209 4.81091 0.41926 5.05108 0.41926C5.29125 0.41926 5.48595 0.609209 5.48595 0.843524L5.48595 4.47942L9.21275 4.47942C9.45292 4.47942 9.64762 4.66937 9.64762 4.90369C9.64762 5.138 9.45292 5.32795 9.21275 5.32795L5.48595 5.32795L5.48595 8.96385C5.48595 9.19816 5.29125 9.38811 5.05108 9.38811C4.81091 9.38811 4.61621 9.19816 4.61621 8.96385L4.61621 5.32795L0.889414 5.32795C0.649242 5.32795 0.454543 5.138 0.454543 4.90369C0.454543 4.66937 0.649242 4.47942 0.889414 4.47942L4.61621 4.47942Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
