import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "12px",
  height = "17px",
  fill = "#5E6977",
  className = "",
  viewBox = "0 0 12 17",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.6 4.8C1.3791 4.8 1.2 4.9791 1.2 5.2V16.4C1.2 16.6209 1.3791 16.8 1.6 16.8H10.4C10.6209 16.8 10.8 16.6209 10.8 16.4V5.2C10.8 4.9791 10.6209 4.8 10.4 4.8H1.6Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.7143 1.2V1C7.7143 0.44771 7.2666 0 6.7143 0H5.2857C4.7334 0 4.2857 0.44771 4.2857 1V1.2H0.5C0.2239 1.2 0 1.42386 0 1.7V1.9C0 2.1761 0.2239 2.4 0.5 2.4H11.5C11.7761 2.4 12 2.1761 12 1.9V1.7C12 1.42386 11.7761 1.2 11.5 1.2H7.7143Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
