import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "20px",
  height = "32px",
  viewBox = "0 0 20 32",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.84626 1.81818H13.1247V0H6.84626V1.81818ZM6.84626 4.69721H13.1247V2.90909H6.84626V4.69721ZM17.9978 20.8233L11.4144 27.297L13.3407 29.1908L17.9978 24.6099V20.8233ZM17.9978 15.4705L13.3746 19.3028V22.529L17.9978 17.9821V15.4705ZM13.3746 12.7457V16.5547C14.6591 15.3135 17.6439 13.3886 17.6439 13.3886L17.8379 13.2335L13.3746 12.7457ZM17.9978 7.81867H1.97821V11.2979L6.62735 10.7898V10.7879H8.62655V24.5547L9.97101 25.8773L11.3754 24.4955V10.7879H13.3746V10.7908L17.9978 11.296V7.81867ZM6.62735 16.4625V12.7447L2.08816 13.2422L2.36905 13.3372C2.36905 13.3372 5.31387 15.2204 6.62735 16.4625ZM1.97821 18.016L6.62735 22.5891V19.2145L1.97821 15.3901V18.016ZM1.97821 24.641L6.60336 29.1898L8.52759 27.297L1.97821 20.8562V24.641ZM20 18.8538L19.997 18.8567V25.4245H19.943L20 25.4817L13.3727 32L13.2817 31.9108L13.2497 31.9428L9.97101 28.7176L6.63335 32L6.54438 31.9127L6.51439 31.9428L0 25.536L0.117953 25.4196H0.00499812V18.9159L0 18.911L0.00499812 18.9052V13.4701H0V11.5142L0.00499812 11.5132V7.32606V5.87927H19.997V6.30303V7.81867V11.5142V13.4701V18.8499L20 18.8538Z"
      fill="#3D50DF"
    />
  </svg>
);

export default SVG;
