import React from "react";
import { Icon } from "../Icon";
import { Link } from "react-router-dom";
import { useViewerNameQuery } from "../../graphql/Queries/__generated__/ViewerNameQuery";

const Header = () => {
  const handleLogout = () => {
    localStorage.removeItem("tokenData");
    window.location.href = "/sign-in";
  };

  const { data } = useViewerNameQuery();
  const viewerName = data?.auth?.viewer?.name;

  return (
    <header className="sticky top-0 z-10 bg-white shadow">
      <div className="h-20 mx-10 flex items-center container-desktop justify-between">
        <Link to={"/incidents"}>
          <Icon name="full-logo" />
        </Link>
        <div className="flex gap-2">
          <span className="font-bold text-base text-black">{viewerName}</span>
          <span>|</span>
          <button
            className="cursor-pointer font-bold text-base text-royalBlue"
            onClick={handleLogout}
          >
            Выйти
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
