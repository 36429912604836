import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "40px",
  height = "40px",
  viewBox = "0 0 40 40",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={width}
      height={height}
      rx="20"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      d="M25.5323 16.5024C25.0991 15.9875 24.2994 15.7775 22.772 15.7775H17.2278C15.6656 15.7775 14.8523 16.0011 14.4207 16.5493C14 17.0838 14 17.8713 14 18.9613V21.0388C14 23.1505 14.4992 24.2226 17.2278 24.2226H22.7721C24.0966 24.2226 24.8305 24.0373 25.3053 23.5828C25.7922 23.1169 26 22.356 26 21.0388V18.9613C26 17.8118 25.9674 17.0196 25.5323 16.5024ZM21.704 20.2869L19.1864 21.6027C19.1302 21.6321 19.0686 21.6467 19.0071 21.6467C18.9375 21.6467 18.8681 21.6279 18.8066 21.5907C18.6908 21.5205 18.62 21.395 18.62 21.2596V18.6364C18.62 18.5013 18.6906 18.3759 18.8062 18.3056C18.9218 18.2354 19.0656 18.2306 19.1855 18.2929L21.7031 19.6002C21.8312 19.6667 21.9117 19.799 21.9119 19.9432C21.912 20.0876 21.8319 20.2201 21.704 20.2869Z"
      fill="#5E6977"
    />
  </svg>
);

export default SVG;
