import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "22px",
  height = "15px",
  fill = "#3D50DF",
  viewBox = "0 0 22 15",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0Z"
      fill={fill}
    />
    <path
      d="M11 4.5C9.34 4.5 8 5.84 8 7.5C8 9.16 9.34 10.5 11 10.5C12.66 10.5 14 9.16 14 7.5C14 5.84 12.66 4.5 11 4.5Z"
      fill="white"
    />
  </svg>
);

export default SVG;
