import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "32px",
  height = "32px",
  fill = "#5E6977",
  viewBox = "0 0 32 32",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15.5" stroke={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3624 10.7527C23.2125 11.6028 23.2125 12.9811 22.3624 13.8313L13.3694 22.8243C13.2569 22.9368 13.1043 23 12.9452 23H9.6C9.26863 23 9 22.7314 9 22.4V19.0548C9 18.8957 9.06321 18.7431 9.17574 18.6306L18.1687 9.6376C19.0189 8.78747 20.3972 8.78747 21.2473 9.6376L22.3624 10.7527ZM21.5139 12.9828C21.8954 12.6013 21.8954 11.9827 21.5139 11.6012L20.3988 10.4861C20.0173 10.1046 19.3988 10.1046 19.0173 10.4861L17.376 12.1274L19.8726 14.624L21.5139 12.9828ZM19.0241 15.4726L16.5275 12.976L10.2 19.3034V21.8H12.6966L19.0241 15.4726Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
