import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import { errorHandler } from "./utils/errors";

export const API_URL = `https://${process.env.REACT_APP_API_URL}/graphql`;

const httpLink = new HttpLink({
  uri: API_URL,
  credentials: "same-origin",
});

const cache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [],
      },
    },
  }),
});

const request = async (operation) => {
  let token = JSON.parse(localStorage.getItem("tokenData"));
  if (token && token.expiresIn < new Date().getTime()) {
    token = await refreshToken(token.value, token.refreshToken);
  }
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token.value}` : "",
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        sendToLoggingService(graphQLErrors);
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    requestLink,
    httpLink,
  ]),
  cache,
});

const refreshToken = async (token, refreshToken) => {
  try {
    const fetchResult = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: `
        mutation {
          guest {
            users {
              refreshSession(refreshToken: "${refreshToken}" ) {
                value
                refreshToken
                expiresIn
              }
            }
          }
        }
      `,
      }),
    });

    const refreshResponse = await fetchResult.json();

    const refreshSession = refreshResponse.data.guest.users.refreshSession;
    saveToken(refreshSession);

    return refreshSession;
  } catch (e) {
    await apolloClient.clearStore();
    localStorage.removeItem("tokenData");
    window.location.href = "/sign-in";
    throw new Error("Failed to fetch fresh access token");
  }
};

const sendToLoggingService = (graphQLErrors) => {
  if (graphQLErrors)
    graphQLErrors.forEach(async ({ message, locations, path }) => {
      errorHandler(message);
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
};

export const saveToken = (token) => {
  localStorage.setItem(
    "tokenData",
    JSON.stringify({
      ...token,
      expiresIn: new Date().getTime() + token.expiresIn * 1000,
    })
  );
};

export default apolloClient;
