import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "19px",
  height = "19px",
  viewBox = "0 0 19 19",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1035 0H3.89648C1.74796 0 0 1.74796 0 3.89648V15.1035C0 17.252 1.74796 19 3.89648 19H15.1035C17.252 19 19 17.252 19 15.1035V3.89648C19 1.74796 17.252 0 15.1035 0ZM17.8867 15.1035C17.8867 16.6382 16.6382 17.8867 15.1035 17.8867H3.89648C2.36183 17.8867 1.11328 16.6382 1.11328 15.1035V3.89648C1.11328 2.36183 2.36183 1.11328 3.89648 1.11328H15.1035C16.6382 1.11328 17.8867 2.36183 17.8867 3.89648V15.1035Z"
      fill="#5E6977"
    />
    <path
      d="M11.3423 4.58428C11.2782 4.34493 11.1247 4.14484 10.9101 4.02096C10.4671 3.76521 9.89863 3.91747 9.6428 4.36055L9.5 4.60785L9.3572 4.36052C9.10141 3.91754 8.53293 3.76521 8.08992 4.02093H8.08988C7.64687 4.27672 7.49457 4.8452 7.75033 5.28825L8.42873 6.46328L5.92555 10.799H4.10059C3.58903 10.799 3.17285 11.2151 3.17285 11.7267C3.17285 12.2382 3.58903 12.6544 4.10059 12.6544H11.1462L10.0749 10.799H8.06806L11.2496 5.28829C11.3735 5.07368 11.4065 4.82364 11.3423 4.58428Z"
      fill="#5E6977"
    />
    <path
      d="M14.8996 10.7988H13.0746L10.9999 7.20532L9.92871 9.06079L13.15 14.6401C13.2739 14.8547 13.4739 15.0083 13.7133 15.0724C13.7932 15.0938 13.8743 15.1045 13.9549 15.1045C14.1157 15.1045 14.2744 15.0623 14.4174 14.9797C14.8604 14.7239 15.0127 14.1554 14.7569 13.7124L14.1459 12.6542H14.8996C15.4111 12.6542 15.8273 12.2381 15.8273 11.7265C15.8273 11.2149 15.4111 10.7988 14.8996 10.7988Z"
      fill="#5E6977"
    />
    <path
      d="M4.4257 13.3965L4.24324 13.7125C3.98748 14.1555 4.13981 14.724 4.58283 14.9798C4.72855 15.0639 4.88783 15.1039 5.04513 15.1039C5.36598 15.1039 5.67848 14.9375 5.85015 14.6402L6.56822 13.3965H4.4257Z"
      fill="#5E6977"
    />
  </svg>
);

export default SVG;
