import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "32px",
  height = "32px",
  fill = "#5E6977",
  className = "",
  viewBox = "0 0 32 32",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6 12.8C11.3791 12.8 11.2 12.9791 11.2 13.2V24.4C11.2 24.6209 11.3791 24.8 11.6 24.8H20.4C20.6209 24.8 20.8 24.6209 20.8 24.4V13.2C20.8 12.9791 20.6209 12.8 20.4 12.8H11.6Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7143 9.2V9C17.7143 8.44771 17.2666 8 16.7143 8H15.2857C14.7334 8 14.2857 8.44771 14.2857 9V9.2H10.5C10.2239 9.2 10 9.42386 10 9.7V9.9C10 10.1761 10.2239 10.4 10.5 10.4H21.5C21.7761 10.4 22 10.1761 22 9.9V9.7C22 9.42386 21.7761 9.2 21.5 9.2H17.7143Z"
      fill={fill}
    />
    <path
      d="M16 0C7.17785 0 0 7.17723 0 16C0 24.8228 7.17785 32 16 32C24.8222 32 32 24.8228 32 16C32 7.17723 24.8222 0 16 0ZM16 30.7692C7.85662 30.7692 1.23077 24.1434 1.23077 16C1.23077 7.85662 7.85662 1.23077 16 1.23077C24.1434 1.23077 30.7692 7.85662 30.7692 16C30.7692 24.1434 24.1434 30.7692 16 30.7692Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
