import React from "react";
import { SVGProps } from "../Icon.d";

const SVG = ({
  width = "40px",
  height = "40px",
  viewBox = "0 0 40 40",
  className = "",
}: SVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={width}
      height={height}
      rx="19.9998"
      fill="white"
      fillOpacity="0.6"
    />
    <path
      d="M25.9966 26.0001V25.9996H25.9996V21.5986C25.9996 19.4456 25.5361 17.7871 23.0191 17.7871C21.8091 17.7871 20.9971 18.4511 20.6656 19.0806H20.6306V17.9881H18.2441V25.9996H20.7291V22.0326C20.7291 20.9881 20.9271 19.9781 22.2206 19.9781C23.4951 19.9781 23.5141 21.1701 23.5141 22.0996V26.0001H25.9966Z"
      fill="#5E6977"
    />
    <path d="M14.1982 17.9885H16.6862V26H14.1982V17.9885Z" fill="#5E6977" />
    <path
      d="M15.441 14C14.6455 14 14 14.6455 14 15.441C14 16.2365 14.6455 16.8955 15.441 16.8955C16.2365 16.8955 16.882 16.2365 16.882 15.441C16.8815 14.6455 16.236 14 15.441 14V14Z"
      fill="#5E6977"
    />
  </svg>
);

export default SVG;
